import { Button, Heading, useMediumFromMediaQuery } from '@madpaws/design-system';
import getConfig from 'next/config';
import Image from 'next/image';
import React from 'react';

import { ImageWithFallback } from '~/common/components/ImageWithFallback/ImageWithFallback';
import {
  PET_CHEMIST_BASE_LINK,
  SASH_BASE_LINK,
  WAGGLY_BASE_LINK,
} from '~/common/constants/externalLinks';
import { trackEvent } from '~/components/analytics/analytics';
import { OTHER_BRAND_CROSS_SELL_CLICK } from '~/components/analytics/constants';
import { viewPageNameResolver } from '~/components/analytics/utils';

import styles from './ExploreMoreProducts.module.css';

import type { Variant } from '@madpaws/design-system/dist/components/Button/Button';
import type { ReactElement } from 'react';

const { publicRuntimeConfig } = getConfig();

type Product = {
  className: string;
  imageName: string;
  imageSize: {
    height: number;
    width: number;
  };
  link: string;
  linkName: string;
  logo: string;
  name: string;
  variant: Variant;
};

const products: Product[] = [
  {
    className: `${styles.product} ${styles.petChemist}`,
    name: 'Shop pet health',
    variant: 'petChemist',
    logo: '/images/homepage/PetChemist.svg',
    linkName: 'Pet Chemist',
    imageName: 'petchemist',
    link: `${PET_CHEMIST_BASE_LINK}/?utm_source=madpaws&utm_medium=futures&utm_campaign=homepage`,
    imageSize: {
      height: 22,
      width: 171,
    },
  },
  {
    className: `${styles.product} ${styles.waggly}`,
    name: 'Shop toys and treats',
    variant: 'waggly',
    logo: '/images/homepage/Waggly.svg',
    imageName: 'waggly',
    linkName: 'Waggly',
    link: `${WAGGLY_BASE_LINK}/?utm_source=madpaws&utm_medium=futures&utm_campaign=homepage`,
    imageSize: {
      height: 36,
      width: 140,
    },
  },
  {
    className: `${styles.product} ${styles.sash}`,
    name: 'Shop dog beds',
    variant: 'sash',
    logo: '/images/homepage/Sash.svg',
    imageName: 'sash',
    linkName: 'Sash',
    link: `${SASH_BASE_LINK}/?utm_source=madpaws&utm_medium=futures&utm_campaign=homepage`,
    imageSize: {
      height: 31,
      width: 164,
    },
  },
];

export const ExploreMoreProducts = (): ReactElement => {
  const isMediumFromViewport = useMediumFromMediaQuery();

  const handleClickedEvent = (link: string, linkName: string): void => {
    const originalUrl = new URL(window.location.href);
    const pathName = originalUrl.pathname;

    const payload = {
      link: link,
      link_name: linkName,
      page: viewPageNameResolver(pathName),
    };

    trackEvent(OTHER_BRAND_CROSS_SELL_CLICK, payload);
  };

  return (
    <div className={styles.root}>
      <div className={styles.headingContainer}>
        <Heading
          alignment={isMediumFromViewport ? 'leftAlign' : 'centerAlign'}
          fontFamily="heading"
          level={2}
          size={isMediumFromViewport ? 'medium' : 'small1x'}
        >
          Other brands in the Mad Paws Group
        </Heading>
      </div>

      <div className={styles.productsContainer}>
        {products.map(
          ({ name, variant, className, imageName, logo, link, linkName, imageSize }) => (
            <div className={className} key={name}>
              <div className={styles.logoButtonContainer}>
                <Image
                  alt={`Logo ${imageName}`}
                  height={imageSize.height}
                  src={`${publicRuntimeConfig.staticPath}${logo}`}
                  width={imageSize.width}
                />
                <a href={link} key={name} rel="noreferrer" target="_blank">
                  <Button
                    label={name}
                    onClick={(): void => handleClickedEvent(link, linkName)}
                    size="small1x"
                    variant={variant}
                  />
                </a>
              </div>
              <ImageWithFallback
                alt="Picture of dog walker walking with two dogs"
                fallback={`${publicRuntimeConfig.staticPath}/images/homepage/${imageName}.jpg`}
                height={269}
                loading="eager"
                webpSrc={`${publicRuntimeConfig.staticPath}/images/homepage/${imageName}.webp`}
                width={516}
              />
            </div>
          )
        )}
      </div>
    </div>
  );
};
