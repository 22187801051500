import { Heading, useLargeFromMediaQuery, IconStar } from '@madpaws/design-system';
import getConfig from 'next/config';

import { ImageWithFallback } from '~/common/components/ImageWithFallback/ImageWithFallback';
import { DesktopSearchWidgetWrapper } from '~/common/components/SearchBarWidget/DesktopSearchWidgetWrapper/DesktopSearchWidgetWrapper';
import { MobileSearchWidgetWrapper } from '~/common/components/SearchBarWidget/MobileSearchWidgetWrapper/MobileSearchWidgetWrapper';

import styles from './Hero.module.css';
import { PRODUCT_REVIEW_LINK } from './constants';

import type { ReactElement } from 'react';
import type { SearchFilters } from '~/common/types/search';

const { publicRuntimeConfig } = getConfig();

type Props = {
  handleFiltersSave: (searchFilters: SearchFilters) => void;
  searchFilters: SearchFilters;
};

export const Hero = ({ searchFilters, handleFiltersSave }: Props): ReactElement => {
  const isLargeFromViewport = useLargeFromMediaQuery();

  const renderProductReview = (): ReactElement => (
    <a href={PRODUCT_REVIEW_LINK} rel="noreferrer" target="_blank">
      <div className={styles.productReview}>
        <div className={styles.review}>
          <p className={styles.rate}>4.6</p>
          <IconStar />
        </div>
        <p>from 2,943 reviews on</p>
        <img
          alt="Product Review"
          height={24}
          src={`${publicRuntimeConfig.staticPath}/images/homepage/product-review-logo.svg`}
          width={139}
        />
      </div>
    </a>
  );

  return (
    <div className={styles.root}>
      <div className={styles.titleContainer}>
        <Heading
          alignment="centerAlign"
          fontFamily="heading"
          hasTightLineHeight
          level={2}
          size={isLargeFromViewport ? 'large2x' : 'large1x'}
        >
          Press paws on pet stress
        </Heading>
        <Heading alignment="centerAlign" level={1}>
          Find trusted pet sitters & dog walkers near you
        </Heading>
      </div>
      {!isLargeFromViewport && (
        <div className={styles.mobileSearchWrapper}>
          <MobileSearchWidgetWrapper
            handleFiltersSave={handleFiltersSave}
            searchFilters={searchFilters}
          />
          {renderProductReview()}
        </div>
      )}
      <DesktopSearchWidgetWrapper
        handleFiltersSave={handleFiltersSave}
        searchFilters={searchFilters}
      />
      <div className={styles.imageWrapper}>
        {isLargeFromViewport ? (
          <ImageWithFallback
            alt="Picture of dog walker walking with two dogs"
            fallback={`${publicRuntimeConfig.staticPath}/images/homepage/homepage_desktop.jpg`}
            height={526}
            loading="eager"
            webpSrc={`${publicRuntimeConfig.staticPath}/images/homepage/homepage_desktop.webp`}
            width={1280}
          />
        ) : (
          <ImageWithFallback
            alt="Picture of dog walker walking with two dogs"
            fallback={`${publicRuntimeConfig.staticPath}/images/homepage/homepage_mobile.jpg`}
            height={290}
            loading="eager"
            webpSrc={`${publicRuntimeConfig.staticPath}/images/homepage/homepage_mobile.webp`}
            width="100%"
          />
        )}
      </div>
      {isLargeFromViewport && renderProductReview()}
    </div>
  );
};
